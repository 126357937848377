<template>
  <v-data-table
    class="elevation-1"
    :items="data"
    :headers="computedHeaders"
    :loading="tableLoading"
  >
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
      <v-icon small class="mr-2" @click="remove(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:item.gut="{ item }">
      <v-chip :color="getColor(item.gut)" dark>{{ item.gut }}</v-chip>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Necessidades</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Novo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline"></span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-row>
                      <v-text-field
                        v-model="Item.nome"
                        label="Nome"
                        :error-messages="errors['nome']"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-textarea
                        v-model="Item.descricao"
                        label="Descrição"
                        :error-messages="errors['descricao']"
                      ></v-textarea>
                    </v-row>
                    <v-row>
                      <v-textarea
                        v-model="Item.justificativa"
                        label="Justificativa"
                        :error-messages="errors['justificativa']"
                      ></v-textarea>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          :items="gravidade"
                          v-model="Item.gravidade"
                          item-text="text"
                          item-value="value"
                          :error-messages="errors['gravidade']"
                          label="Gravidade"
                        ></v-select>
                      </v-col>

                      <v-col>
                        <v-select
                          :items="urgencia"
                          v-model="Item.urgencia"
                          item-text="text"
                          item-value="value"
                          :error-messages="errors['urgencia']"
                          label="Urgência"
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                          :items="tendencia"
                          v-model="Item.tendencia"
                          item-text="text"
                          item-value="value"
                          :error-messages="errors['tendencia']"
                          label="Tendência"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
              <v-btn :loading="saving" color="darken-1" text @click="save"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { Necessidade } from "@/api/eqpdti";
export default {
  name: "Necessidade",
  data: () => ({
    gravidade: [
      {
        value: 1,
        text: "Sem gravidade",
      },
      {
        value: 2,
        text: "Pouco grave",
      },
      {
        value: 3,
        text: "Grave",
      },
      {
        value: 4,
        text: "Muito grave",
      },
      {
        value: 5,
        text: "Extremamente grave",
      },
    ],
    urgencia: [
      {
        value: 1,
        text: "Pode esperar",
      },
      {
        value: 2,
        text: "Pouco urgente",
      },
      {
        value: 3,
        text: "Urgente, merece atenção no curto prazo",
      },
      {
        value: 4,
        text: "Muito urgente",
      },
      {
        value: 5,
        text: "Necessidade de ação imediata",
      },
    ],
    tendencia: [
      {
        value: 1,
        text: "Não irá mudar",
      },
      {
        value: 2,
        text: "Irá piorar a longo prazo",
      },
      {
        value: 3,
        text: "Irá mudar a médio prazo",
      },
      {
        value: 4,
        text: "Irá mudar a curto prazo",
      },
      {
        value: 5,
        text: "Irá mudar rapidamente",
      },
    ],
    data: [],
    headers: [
      {
        text: "ID",
        value: "necessidade_id",
        align: "center",
      },
      {
        text: "Nome",
        value: "nome",
        align: "left",
      },
      {
        text: "GUT",
        value: "gut",
        align: "center",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    Item: {},
    dialog: false,
    saving: false,
    tableLoading: false,
    errors: [],
  }),
  computed: {
    computedHeaders() {
      this.fetch();
      return this.headers;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.Item = {};
      });
    },
    save() {
      this.saving = true;

      const _then = () => {
        this.saving = false;
        this.close();
        this.fetch();
      };
      const _catch = (error) => {
        this.saving = false;
        this.errors = error.response.data;
        if (this.errors["non_field_errors"]) {
          this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
        } else {
          this.$emitter.emit("alert:error", this.errors);
        }
      };

      if (this.Item["id"]) {
        let id = this.Item["id"];

        Necessidade.update(id, this.Item).then(_then).catch(_catch);
      } else {
        Necessidade.post(this.Item).then(_then).catch(_catch);
      }
    },
    edit(item) {
      this.Item = Object.assign({}, item);
      this.dialog = true;
    },

    remove(item) {
      if (window.confirm("Deseja realmente excluir?")) {
      this.tableLoading = true;
      Necessidade.remove(item["id"]).then(() => {
        this.tableLoading = false;
        this.fetch();
      });
      }
    },
    fetch() {
      this.tableLoading = true;
      Necessidade.list().then(({ data }) => {
        this.data = data.map((item) => {
          item.gut = item.tendencia * item.urgencia * item.gravidade;
          return item;
        });
        this.tableLoading = false;
      });
    },
    getColor(gut) {
      if (gut > 85) return "red";
      else if (gut > 45) return "orange";
      else return "green";
    },
  },
  // mounted() {
  //   this.fetch();
  // },
};
</script>
